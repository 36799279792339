enum ActionTypes {
    GET_VEHICLE_LIST = 'settingsObjects/GET_VEHICLE_LIST',
    SET_VEHICLE_LIST = 'settingsObjects/SET_VEHICLE_LIST',
    // GET_LIST_VEHICLE = 'settingsObjects/GET_LIST_VEHICLE',
    // UPDATE_LIST_VEHICLE = 'settingsObjects/UPDATE_LIST_VEHICLE',
    GET_VEHICLE_BY_ID = 'settingsObjects/GET_VEHICLE_BY_ID',
    UPDATE_VEHICLE = 'settingsObjects/UPDATE_VEHICLE',
    UPDATE_VEHICLE_BY_ATTRIBUTE = 'settingsObjects/UPDATE_VEHICLE_BY_ATTRIBUTE',
    GET_LIST_VEHICLE_TYPES = 'settingsObjects/GET_LIST_VEHICLE_TYPES',
    UPDATE_LIST_VEHICLE_TYPES = 'settingsObjects/UPDATE_LIST_VEHICLE_TYPES',
    GET_LIST_VEHICLE_KINDS = 'settingsObjects/GET_LIST_VEHICLE_KINDS',
    UPDATE_LIST_VEHICLE_KINDS = 'settingsObjects/UPDATE_LIST_VEHICLE_KINDS',
    // GET_LIST_DEVICE = 'settings/GET_LIST_DEVICE',
    // UPDATE_LIST_DEVICE = 'settings/UPDATE_LIST_DEVICE',
    UPDATE_VEHICLE_DATA = 'settingsObjects/UPDATE_VEHICLE_DATA',
    // SET_VEHICLE_ID = 'settingsObjects/SET_VEHICLE_ID',
    SET_VEHICLE_CRUD_MODE = 'settingsObjects/SET_VEHICLE_CRUD_MODE',
    DELETE_VEHICLE = 'settingsObjects/DELETE_VEHICLE',
    SET_SENSOR = 'settingsObjects/SET_SENSOR',
    UPDATE_SENSOR_BY_ATTRIBUTE = 'settingsObjects/UPDATE_SENSOR_BY_ATTRIBUTE',
    UPDATE_SENSORS_STATUS = 'settingsObjects/UPDATE_SENSORS_STATUS',
    UPDATE_SENSOR = 'settingsObjects/UPDATE_SENSOR',
    DELETE_SENSOR = 'settingsObjects/DELETE_SENSOR',
    GET_LIST_SENSOR_TYPES = 'settingsObjects/GET_LIST_SENSOR_TYPES',
    SET_LIST_SENSOR_TYPES = 'settingsObjects/SET_LIST_SENSOR_TYPES',
    GET_LIST_SENSOR_TYPE_GROUPS = 'settingsObjects/GET_LIST_SENSOR_TYPE_GROUPS',
    SET_LIST_SENSOR_TYPE_GROUPS = 'settingsObjects/SET_LIST_SENSOR_TYPE_GROUPS',
    GET_LIST_SENSOR_MODELS = 'settingsObjects/GET_LIST_SENSOR_MODELS',
    SET_LIST_SENSOR_MODELS = 'settingsObjects/SET_LIST_SENSOR_MODELS',
    GET_LIST_SENSOR_BRANDS = 'settingsObjects/GET_LIST_SENSOR_BRANDS',
    SET_LIST_SENSOR_BRANDS = 'settingsObjects/SET_LIST_SENSOR_BRANDS',
    GET_VEHICLE_SENSORS = 'settingsObjects/GET_VEHICLE_SENSORS',
    SET_VEHICLE_SENSORS = 'settingsObjects/SET_VEHICLE_SENSORS',
    GET_VEHICLE_LIST_BY_USER = 'settingsObjects/GET_VEHICLE_LIST_BY_USER',
    SET_VEHICLE_LIST_BY_USER = 'settingsObjects/SET_VEHICLE_LIST_BY_USER',
    SET_VEHICLE_COUNTERS = 'settingsObjects/SET_VEHICLE_COUNTERS',
    IMPORT_VEHICLES_POSITIONS = 'settingsObjects/IMPORT_VEHICLES_POSITIONS',
    EXPORT_VEHICLES_POSITIONS = 'settingsObjects/EXPORT_VEHICLES_POSITIONS'
}

export default ActionTypes;